<template>
  <DashboardTemplate>
    <el-card class="box-card">
      <h2>Update Business Event Card</h2>
      <el-table border :data="tableData" style="width: 100%">
        <el-table-column
          type="index"
          width="60"
          align="center"
          label="ลำดับ">
        </el-table-column>
        <el-table-column prop="team" header-align="center" label="Team"></el-table-column>
        <el-table-column prop="cardId" label="Business Event Card's Number">
          <template slot-scope="scope">
            <RandomNumber :min="0" :max="16" :showNumber="false" @runningNumber="rollingNumber($event,scope.$index)" @stopNumber="setRandom($event,scope.$index)" />
            <span v-show="false">{{randomNumber}}</span>
            <el-select v-model="scope.row.cardId" placeholder="Card number">
              <el-option v-for="item in cardId" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" class="mg-t-3">
        <el-col :span="6">
          <button
            v-if="typeof user.userLevel != 'undefined' && user.userLevel == 80"
            class="mg-y-5 w-100 btn-primary"
            type="submit"
            @click="submit()"
            round
          >{{room.currentRound < 6 ? "Next" : "Result"}}</button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card mg-t-1">
      <el-collapse>
        <el-collapse-item title="Business Event detail" name="1">
          <el-table border :data="cardData" style="width: 100%">
            <el-table-column prop="cardId" align="center" label="Card Number" width="150"></el-table-column>
            <el-table-column prop="Detail" label="Detail"></el-table-column>
            <el-table-column prop="Action" label="Action">
              <template slot-scope="scope">
                <span :class="scope.row.cardId!=0?(checkActionEvent(scope.row.cardId))?'color-success':'color-danger':''">{{scope.row.Action}}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-dialog :visible.sync="confirmModal" width="30%">
        <h3 class="text-center">สรุปผลการขายทั้งหมด</h3>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmModal = false">Cancle</el-button>
        <el-button type="primary" @click="submitEvent()" :loading="loadingConfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </DashboardTemplate>
</template>
<script>
import { updateCurrent } from "@/service/updateCurrent";
import { HTTP } from "@/service/axios";
import { alertFailError } from "@/service/alertMsg";
import DashboardTemplate from "@/template/DashboardTemplate";
import RandomNumber from "@/components/RandomNumber";
export default {
  components: {
    DashboardTemplate,
    RandomNumber
  },
  computed: {
    room() {
      return this.$store.state.room;
    },
    user() {
      return this.$store.state.user;
    },
    cardData(){
      return this.$store.state.cardData
    }
  },
  data() {
    return {
      confirmModal:false,
      tableData: [],
      randomNumber:[],
      cardId: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],
      loadingConfirm:false
    };
  },
  mounted(){
    this.fetchRoom()
  },
  methods: {
    checkActionEvent(id){
      if(id == 10 || id == 11 || id == 12 || id == 13 || id == 14){
        return true
      }else{
        return false
      }
    },
    rollingNumber(e,i){
      this.tableData[i].cardId = e
      this.randomNumber = e
    },
    setRandom(event,index){
      this.tableData[index].cardId = event
    },
    fetchRoom(){
      let obj = {
          number:this.room.roomNumber
      }
      return this.$store.dispatch('FETCH_GROUPS',obj).then(() => {
        this.room.groups.map(data =>{
          let item = {
            team: data.name,
            cardId:null,
            groupId: data._id
          }
          this.tableData.push(item)
        })
      });
    },
    submitEvent(){
      this.loadingConfirm = true
      let data = {
        roomId: this.room._id,
        round: this.room.currentRound,
        users:this.tableData
      }
      HTTP.post('/business/event/card',data).then(async (res)=>{

        if(res.data.success){
          this.room.currentRound == 6 ? this.room.current = "World's demand" : this.room.current = "Investment";
          await this.$store.dispatch("updateCurrent", this.room);
          await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
          await this.$socket.client.emit('getCardsId',{roomId:this.room._id,round:this.room.currentRound})
          this.room.currentRound == 6 ? this.$router.push("/world-demand").catch(err => {}) : this.$router.push("/waiting-investment").catch(err => {})
        }
      })
      .finally(()=>{
        setTimeout(() => {
          this.loadingConfirm = false
        }, 500);
      })
    },
    async submit() {
      let check = 0;
      await this.tableData.map(item => {
        if (item.cardId == null) {
          check++;
        }
      })
      if(check != 0){
        this.$message.error({
          showClose: true,
          message: "กรุณาเลือก Business Event ให้ครบทุกทีม",
          type: "error"
        });
      }else{
        if(this.room.currentRound == 6){
          this.confirmModal = true
        }else{
          this.submitEvent()
        }
      }
    }
  }
};
</script>